import Loading from './Loading';
import { useParams, useNavigate } from 'react-router-dom';
import { loginWithVkn } from '../service/request/companyRequest';
import { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { setIsAuthenticatedWithVkn } from '../store/auth/authSlice';
import { getByUser } from '../service/request/companyRequest';
import { setSelectCompany } from '../store/company/userCompanySlice';

export default function LoginWithVkn() {
    const { vkn } = useParams();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const findModuleByType = (modules, moduleType) => {
        return modules.find((module) => module.setting?.settings?.program?.title?.toLowerCase().includes(moduleType.toLowerCase()));
    };

    const fetchLoginWithVkn = async () => {
        try {
            // VKN ile giriş yap
            const loginResponse = await loginWithVkn({ vkn });
            if (!loginResponse.data?.data) {
                throw new Error('Giriş başarısız');
            }

            // Auth state'i güncelle
            dispatch(
                setIsAuthenticatedWithVkn({
                    isAuthenticated: true,
                    user: loginResponse.data.data.auth.user,
                    token: loginResponse.data.data.auth.token,
                    taxNumber: loginResponse.data.data.taxNumber,
                    program: loginResponse.data.data.program
                })
            );

            // Kullanıcının şirketlerini getir
            const companiesResponse = await getByUser();
            const companies = companiesResponse.data.data;

            // VKN'ye göre şirketi bul
            const company = companies.find((c) => c.tax_number === loginResponse.data.data.taxNumber);
            if (!company) {
                throw new Error('Şirket bulunamadı');
            }

            // e-defter ve zenom modüllerini kontrol et
            const eDefterModule = findModuleByType(company?.modules, loginResponse?.data?.data?.program);

            if (!eDefterModule) {
                throw new Error('Modül bulunamadı');
            }

            // Şirketi seç ve yönlendir
            dispatch(setSelectCompany(company));
            navigate('/');
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Hata',
                detail: error?.response?.data?.message || 'Tanımlı modül Luca E-Defter değildir.',
                life: 3000
            });
            setTimeout(() => {
                navigate('/login');
            }, 4000);
        }
    };

    useEffect(() => {
        if (vkn) {
            fetchLoginWithVkn();
        } else {
            navigate('/login');
        }
    }, [vkn]);

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <Toast ref={toast} />
            <Loading />
        </div>
    );
}
